.searchContainer {
    display: inline-flex;
    flex: 1 1 auto;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .searchIcon {
    padding: 0.5rem;
    margin: 0.2rem;
  }
  
  .searchBox {
    border: 0;
    padding: 0.5rem 0.5rem 0.5rem 1.5rem;
    flex: 1;
    width: 100wh;
  }
  
  .searchButton {
    background: #bf1e2e;
    border: 5px;
    border-color: #bf1e2e;
    color: white;
    font-weight: 500;
    padding: 0.5rem;
    border-radius: 0;
  }
  * {
    box-sizing: border-box;
  }
  
  form.example input[type=text] {
    padding: 10px;
    font-size: 17px;
    border: 1px solid grey;
    float: left;
    width: 80%;
    background: #f1f1f1;
  }
  
  form.example button {
    float: left;
    width: 20%;
    padding: 10px;
    background: #2196F3;
    color: white;
    font-size: 17px;
    border: 1px solid grey;
    border-left: none;
    cursor: pointer;
  }
  
  form.example button:hover {
    background: #0b7dda;
  }
  
  form.example::after {
    content: "";
    clear: both;
    display: table;
  }
