.noUi-horizontal{
    height: 1px;
    background: rgba(0, 0, 50, 0.12);
    border: none;
    max-width: 500px;
    cursor: pointer;
  }
  .noUi-horizontal .noUi-handle{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    top: -8px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 50, 0.04), 0 4px 6px 0 rgba(0, 0, 50, 0.04), 0 2px 4px 0 rgba(0, 0, 50, 0.04);
    border:none;
    cursor: pointer;
    right: -15px !important;
  }
  .noUi-horizontal .noUi-tooltip{
    bottom: -24px;
    border:none;
    overflow: hidden;
    font-size: 12px;
    line-height: 1.33;
    color: #000032;
    background: transparent;
  }
  .noUi-handle:after, .noUi-handle:before{
    background-color: #000032;
    width: 1px;
    height: 8px;
    top: 4px
  }
  .noUi-handle:after{
    left: 6px;
  }
  .noUi-handle:before{
    left:9px;
  }
  .noUi-handle:focus{
    outline: none
  }

  .noUi-horizontal .noUi-handle {
    width:8px;
    height:25px;
    left: 0px;
    top: -8px;
    border: 0px solid #000000;
    border-radius: 0px;
    background: #000;
    cursor: default;
    box-shadow: none; /*inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;*/ }

.noUi-handle {
     left:0px; }

.noUi-active {
    box-shadow: none /*inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;*/ }

.noUi-handle:before, .noUi-handle:after {
    content: "";
    display: block;
    position: absolute;
    height: 14px;
    width: 0px;
    background: #000000;
    left: 14px;
    top: 6px; }

.noUi-handle:after {
    left: 0px; }